.blog_detail_main_container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.blog_img {
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.blog_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 20px;
}

.desc {
    font-size: 2rem;
    color: rgb(70, 70, 70);
    font-weight: 500;
    line-height: 1.7;
}
@media (max-width:786px) {
    .blog_img {
        width: 100%;
        height: 180px;
    }
    
    
    
}