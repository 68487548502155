.blog_card_main_container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
}

.blog_card_container {
    justify-content: space-between;
    gap: 10px;
}

.blog_btn_main_container {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.blog_btn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.blog_btn {
    z-index: 100;
    gap: 10px;
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    text-align: center;
    width: 200px;
    justify-content: center;
}

.blog_btn:hover {
    color: #ffffff;
    background-color: #000000;
}

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    align-items: center;
}

.page-num2 {
    font-size: 2rem;
    margin: 0 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.page-num,
.active {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin: 0 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15%;
    color: white;
    overflow: hidden;
    padding: 7px;
    background-color: rgb(207, 206, 206);
}

.page-num a,
.page-num2 a {
    color: #007bff;
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-num a:hover,
.page-num2 a:hover {
    background-color: #007bff;
    color: #fff;
}

.active a {
    background-color: #007bff;
    color: #fff;
}

@media (max-width:1250px) {
    .blog_card_container {
        display: flex;
        justify-content: center;

    }
}

@media (max-width:786px) {
    .blog_card_main_container {
        display: flex;
        justify-content: center;
        align-self: center;

    }

    .blog_btn_main_container {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 100%;

    }

    .blog_card_container {
        align-self: center;
        justify-content: center;

    }

    .blog_btn_container {
        display: flex;
        flex-direction: column-reverse;


    }

    .blog_btn {
        margin: 0px auto;
        margin-top: 20px;
    }
}