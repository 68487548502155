
.blog-card:hover{
  cursor: pointer;
  color: #0f4c81;
}
.card-img-top {
  width: 100%;
  height: 270px;
}
.card-title {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0.75rem;
  padding-left: 5px;
  padding-right: 5px;
  
}
.card-text {
  font-size: 18px;
  color: #666;
  margin-bottom: 1rem;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}
.card-body{
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width:1700px) and (max-width:768px) {
  .card-img-top {
    width: 100%;
    height: 180px;
  }
  .card-text {
    font-size: 12px;
    color: #666;
    font-weight: 600;
    padding-left: 5px;
    padding-right: 5px;
  }
  .card-title {
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    
  }
}
