.img_container {
    display: flex;
    background-image: url("https://store.adveits.com/project/malex/react-js/demo/static/media/01_img.e5cc6a0a.jpg");
    position: relative;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: 50%;
    background-size: cover;
    width: 100%;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
  .black_effect {
    background-color: #060606;
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
  .container_data {
    width: 100%;
    z-index: 3;
    margin-top: 60px;
  }
  .margin_left_content{
    margin-left: 200px;
  }
  .scroll_up_text {
    height: 90px;
    overflow: hidden;
    white-space: nowrap;
  }
  .scroll_content {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
  }
  .scroll_text {
    margin-bottom: 10px;
    height: 80px;
    font-size: 70px;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
  }
  .container_image {
    width: 100%;
  }
  .container_des {
    display: flex;
    max-width: 650px;
    font-size: 19px;
    line-height: 22px;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .container_btn_container {
    display: flex;
  }
  
  .container_btn {
    gap: 10px;
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    text-align: center;
    z-index: 100;
  }
  .container_btn:hover {
    color: #000000;
    background-color: #ffffff;
  }
  .scroll_down_container {
    display: flex;
    margin-left: 40px;
    margin-top: 60px;
  }
  
  .scroll_down_arrow {
    z-index: 9;
    color: #ffffff;
  }
  
  .scroll_down_arrow {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
  }
  
  .scroll_down_arrow {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
  }
  @-webkit-keyframes mover {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  
  @media only screen and (max-width: 767px) {
    .container_data {
      width: 100%;
      z-index: 3;
      /* margin-top: -180px; */
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .margin_left_content{
      margin-left: 40px;
    }
    .margin_left{
      margin-left: 0px;
    }
    .scroll_text {
      height: 80px;
      font-size: 45px;
      font-weight: 700;
      color: #fff;
      white-space: nowrap;
      margin-top: 3px;
    }
    .scroll_up_text {
      height: 55px;
      overflow: hidden;
      white-space: nowrap;
    }
    .container_image {
      width: 90%;
    }
    .scroll_down_container {
      display: flex;
      margin-left: 0px;
      margin-bottom: -50px;
     
    }
  }
  