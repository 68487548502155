.tech_container{
    width: 100%;
}
#techcontentSection {
    height: 100%;
    width: 100%;
  }
  
  .techcontentBox {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .techcontentBox .techContent2 {
  
    line-height: 1.5;
  }
  
  .techContent1 h1 {
    font-size: 35px;
  }
  
  .techcontentBox .techContent2 p {
    font-size: 19px;
    color: #666;
  }
  #head_container {
    width: 100%;
    z-index: -9;
    display: flex;
    align-self: center;
    justify-content: center;
    overflow: hidden;
}

.head_data {
    width: 100%;
}

.main_heading  {
    padding-top: 120px;
    color: rgb(0, 0, 0);
    font-size: 37px;
    padding-left: 50px;
    font-weight: bold;
}


.head_img img {
    height: 100%;
    width: 100%;

}

  
