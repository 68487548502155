.service_text{
    font-size: 18px;
    color: black;
}
.service_content{
    font-size: 19px;
    color: #666;
    font-weight: 600;
    line-height: 1.7;
}
@media (max-width: 400px) {
    .service_text{
        font-size: 14px;
        color: black;
    }
  }
  