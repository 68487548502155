.techlogt_itmes {
  display: flex;
  width: 100%;
  padding: 40px 0px;
  justify-content: space-around;
  gap: 30px;
}

.technolgy_box {
  height: 220px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  border-radius: 14px;
  border: 1px solid #262e4b;
}

.technolgy_box:hover {
  cursor: pointer;
  transform: scale(1.1);
}

