.navbar {
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    color: #fff;
    display: flex;
    width: 100%;
    justify-content: center;
}

.nav_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 20px 0px 20px;
}

.scrolled {
    position: fixed;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    /* padding: 20px 20px 0px 20px; */
    padding-top: 15px;
    padding-bottom: 30px;
    transition: background-color 0.3s ease, padding 0.3s ease;
    z-index: 200;

}

.scrolled .nav_link {
    color: #000000;
    /* font-family: poppins; */
    font-size: 15px;
    font-weight: bold;

}

.scrolled .nav_link:hover {
    color: #0f4c81;
}

.scrolled .nav_search {
    border-color: #000000;
    color: #000000;
}

.scrolled .nav_logo img {
    filter: invert(0%);
    max-width: 100%;
    height: auto;
}

.other-page {
    position: absolute;
    background-color: #d6bbbb00;
    transition: background-color 0.3s ease, padding 0.3s ease;
    z-index: 200;
}

.other-page .nav_link {
    color: #000000;
    /* font-family: poppins; */
    font-size: 15px;
    font-weight: bold;

}

.other-page .nav_link:hover {
    color: #0f4c81;
}

.other-page .nav_search {
    border-color: #000000;
    color: #000000;
}

.other-page .nav_logo img {
    filter: invert(0%);
    max-width: 100%;
    height: auto;
}

.nav_link .active {
    color: #0f4c81;
}

.nav_logo img {
    filter: invert(100%);
    max-width: 100%;
    height: auto;
}

.nav_right_container {
    display: flex;
    align-items: center;
}

.nav_links {
    display: flex;
    gap: 25px;
}

.nav_link {
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    margin-right: 1rem;
    cursor: pointer;
}

.nav_link:hover {
    color: #b2a59d;
}

.nav_search {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bolder;
    font-family: "Font Awesome 5 Free";
    margin-left: 1rem;
}

.nav_menu_btn {
    display: none;
}


.fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.fullscreen-menu a {
    text-decoration: none;
    color: #8d8d8d;
    font-size: 24px;
    margin-right: 20px;
    line-height: 50px;
}

.fullscreen-menu a:hover {
    color: #ffffff;
}

.close-menu-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    width: 5rem;
    height: 5rem;
    border: 2px solid white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bolder;
    font-family: "Font Awesome 5 Free";
    margin-left: 1rem;
}

.nav_menu_links {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    text-align: end;
    margin-top: 100px;
}

.fullscreen-menu .nav_menu_links {
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.nav_menu_address {
    position: relative;
    font-size: 2rem;
    color: #424242;
    margin: 20px;
    margin-top: 50px;
}

.nav_menu_contect {
    margin-top: 30px;
    margin: 20px;
    font-size: 2rem;
    color: white;
}

.nav_menu_manufacture {
    color: #424242;
    margin-top: 100px;
    margin: 20px;
    font-size: 2rem;
}


@media (max-width: 768px) {
    .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav_links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #333;
        position: absolute;
        top: 60px;
        left: 0;
        text-decoration: none;
    }




    .other-page .nav_menu_btn {
        color: #000000;
        border: 2px solid rgb(0, 0, 0);
    }

    .nav_menu_btn {
        display: flex;
        width: 2rem;
        height: 2rem;
        border: 1px solid white;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bolder;
        font-family: "Font Awesome 5 Free";
    }

    .scrolled .nav_menu_btn {
        position: fixed;
        border: 2px solid rgb(0, 0, 0);
        color: #000000;
        padding: 0.8rem 1rem;
        transition: background-color 0.3s ease, padding 0.3s ease;
    }

    .scrolled {
        display: none;
    }

    .nav_menu_btn.fixed {
        position: fixed;
        border: 2px solid #000000;
        color: #000000;
        z-index: 10;
    }

    .nav_menu_contect {
        font-size: 1.5rem;
    }

    .nav_menu_manufacture {
        font-size: 1.5rem;
    }

    .nav_menu_address {
        font-size: 1.5rem;
    }
}

@media (max-width:200px) {
    .navbar {
        padding: 40px 15px;
    }

    .scrolled {

        padding: 25px 15px;
    }

  
}