.blog_categorie {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    /* padding: 20px; */
    padding-top: 30px;
  }
  
  .Categories {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 250px;
    max-width: 330px;
    min-width: 290px;
    /* padding: 40px; */
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .Categories-heading {
    font-size: 12px;
  }
  
  .doat {
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background-color: #0f4ca0;
  }
  
  .Categories-text {
    margin-top: 8px;
    line-height: 2rem;
    display: flex;
    text-align: center;
    align-items: center;
    gap: 1rem;
  }
  
  /* --------------------------------------------blog-container-2------------------------------------ */
  
  .Posts {
    margin-bottom: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: auto;
    max-width: 500px;
    overflow: hidden;
    padding: 20px;
  }
  
  .post_img_container {
    width: 90px;
    height: 90px;
    overflow: hidden;
  }
  
  .post-1 img {
    height: 100%;
    width: 100%;
  }
  
  .post-1 {
    display: flex;
    gap: 1rem;
    padding-top: 15px;
    cursor: pointer;
  }
  
  .post-heading {
    font-size: 3rem;
    font-weight: 600;
  }
  
  .Post1-text {
    font-size: 1.8rem;
    font-weight: 500;
    width: 100%;
  }
  
  
  .Post1-img {
    object-fit: cover;
    transition: transform 0.5s ease-out;
  }
  
  .Post1-img:hover {
    transform: scale(1.1);
  }
  
  /* --------------------------------------------blog-container-3------------------------------------ */
  
  /* .Recents {
    margin-bottom: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    line-height: 2rem;
    height: 340px;
    max-width: 330px;
    min-width: 295px;
    padding: 30px;
  }
  
  .Recents-heading {
    font-size: 18px;
    font-weight: bold;
  }
  
  .recent-title {
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .recent-para p {
    line-height: 1.7rem;
    padding-top: 8px;
    font-size: 14px;
    color: gray;
  }
  
  .recent-para p:hover {
    color: #0f73b4;
  }
  
  .hr {
    height: 2px;
    width: 100%;
    margin-top: 20px;
    background-color: #dbdbdb;
  } */
  
  /* --------------------------------------------blog-container-4------------------------------------ */
  
  .tags {
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 180px;
    max-width: 400px;
  
  }
  
  .tags-heading {
    font-size: 18px;
    font-weight: bold;
  }
  
  .tags-buttons button {
    margin-top: 25px;
    height: 30px;
    width: 80px;
    border: 1px solid black;
    background-color: black;
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .tags-buttons button:hover {
    background-color: white;
    color: black;
  }
  
  @media (max-width:786px) {
    .blog_categorie {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }