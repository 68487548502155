.service-card {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
padding: 30px;

}
.service-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 15px;

}

.service-card-title  {
font-size: 20px;
margin-bottom: 16px;
}

.service-card-para p {
font-size: 19px;
color: #666;
font-weight: 600;
line-height: 1.7;
}
.background-text {
  position: absolute;
  top: -50px;
  right: 0;
  font-size: 23rem;
  opacity: 0.07;
  pointer-events: none;
  z-index: 3;
  font-weight: 800;
}


@media (min-width: 786px) {
  .service-card-container {
    display: flex;
    justify-content: center;
  }

  .service-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .service-card {
    padding: 30px;
  }

  .service-card-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .service-card-para {
    font-size: 20px;
    font-weight: 600;
  }

  .service-card {
    position: relative;
  }

    .service-card1-heading h3 {
        font-size: 2rem;
    }
    .service-card {
        padding: 30px;
    }

    .service-card-title h3 {
        font-size: 30px;
        margin-bottom: 16px;
    }

    .service-card-para {
        font-size: 16px;
        font-weight: 600;
    }

    .service-card {
        position: relative;
    }
    .background-text {
        position: absolute;
        top: -50px;
        right: 0;
        font-size: 23rem;
        opacity: 0.07;
        pointer-events: none;
        z-index: 3;
        font-weight: 800;
    }
}

@media (min-width: 200px) {
  .service-card {
    padding: 21px;
  }


  .service-card-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .service-card-para {
    font-size: 17px;
    font-weight: 600;
  }

}
