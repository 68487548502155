.blog_detail_main {
    margin-top: 30px;
    padding: 24px;
}
.blog_detail_text{
    font-size: 18px;
    padding-top: 20px;
}




@media (max-width:786px) {
    .blog_detail_main {
        margin-top: 0;
        padding-top: 0;
    }


    .blog_detail_text{
        font-size: 15px;
        padding-top: 60px;
        text-align: center;
    }
}