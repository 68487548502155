.blog_all_data_container {
    padding: 24px;
  }
  
  .blog_all_data {
    width: 100%;
  }
  .blog_view_text{
    font-size: 18px;
    color: #0f4c9e;
    padding-top: 90px;

  }
  .t_content{
    font-size: 16px;
  }
  
  
  
  @media (min-width:786px) and (max-width:1700px) {
  
    .blog_all_data {
  
      width: 100%;
  
    }
  }
  
  