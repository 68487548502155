.about_main_container {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 20px;
}
.about_us_main {
  background-color: #f8f8f8;
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
}

.about_us_content_section {
  width: 100%;
}

.content_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 20px;
}

.content1 {
  width: 45%;
  height: 100%;
}
.about h3{
  color: #0f4c9e;
  font-size: 30px;
}

.content1 h1 {
  font-size: 25px;
}

.content2 {
  width: 50%;
  height: 100%;
}

.content2 p {
  margin-bottom: 50px;
  font-size: 20px;
  color: #555;
  line-height: 1.7;
  margin-top: 30px;
}

.hor {
  width: 100%;
  background-color: #070707;
  height: 2px;
  /* margin: auto; */
}

.owner_name h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.owner_name p {
  font-size: 1rem;
  font-weight: 400;
  color: "#555";
}

/* Default styles for larger screens */
.about_detail {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f8f8f8;
}

.detail_data {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  align-items: center;
}

.detail_data div:first-child {
  color: #0f4c9e;
  font-size: 21px;
  font-weight: 700;

}

.detail_data div:last-child {
  color: #000;
  font-size: 21px;
  font-weight: 600;
}

.hor {
  width: 100%;
  background-color: #b5b5b5;
  height: 2px;
}
@media (max-width: 786px) {
  html {
    font-size: 55%;
  }


  .about_us_content_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .content_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }



  .content1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .content1 div {
    margin-top: 10px;
  }
  .about h3{
    color: #0f4c9e;
    font-size: 20px;
  }

  .about_detail {

    padding-right: 0px;
  }

  .content2 {
    width: 100%;
  }

  .content2 p {
    width: 100%;
    margin-bottom: 20px;

  }

  .detail_data {
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 10px;
  }

  .hor {
    display: none;
  }


}
@media (max-width:200px) {
  .content1 h1 {
    font-size: 16px;
  }

  .content2 p {
    margin-top: 20px;
    font-size: 16px;
  }
  .about h3{
    color: #0f4c9e;
    font-size: 15px;
  }

  .hor {
    width: 100%;
  }

}