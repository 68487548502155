.scroll_main_container {
  display: flex;
  width: 100%;
  background-color: aqua;
  justify-content: end;
  z-index: 9;
}

.scroll_to_top_button {
  display: flex;
  width: 50px;
  height: 45px;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  background-color: black;
  color: white;
  border-radius: 50%;
  /* padding: 2px 2px; */
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.scroll_to_top_button:hover {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}
