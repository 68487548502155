.service-container2 {
    display: flex;
    justify-content: center;
}

.service-img {
    width: 100%;
    margin-bottom: -10px;
    max-height: 700px;
    overflow: hidden;
}

.service-img img {
    height: auto;
    width: 100%;
    object-fit: cover;
    /* min-width: 400px; */
}

