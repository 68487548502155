.blog_text {
  color: #0f4c9e;
  font-size: 19px;
}
.blog_home_btn_container {
  display: flex;
  padding-top: 30px;
  justify-content: flex-end;
}
.t_content_1{
  font-size: 14px;
}
.t_content p{
  font-size: 19px;
}

.blog_home_btn {
  z-index: 100;
  gap: 10px;
  background-color: transparent;
  border: 2px solid #000000;
  color: #000000;
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  text-align: center;
  margin-bottom: 30px;
}

.blog_home_btn:hover {
  color: #ffffff;
  background-color: #000000;
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {

  .blog_home_btn_container {
    margin-top: 10px;
    margin-right: 0px;
    max-width: 450px;
    width: 100%;
  }
  .blog_text {
    color: #0f4c9e;
    font-size: 14px;
  }
  .blog_home_btn {
    z-index: 9;
    gap: 10px;
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .blog_home_btn_container {
    margin-top: 1rem;
    margin-right: 0;
    max-width: 100%;
  }

  .blog_home_btn {
    font-size: 14px;
    padding: 12px 18px;
  }
  .blogContainer{
    padding-top: 50px;
  }
  
}
@media (max-width: 400px) {
  .blog_text {
    color: #0f4c9e;
    font-size: 14px;
    padding-top: 50px;
  }
}



