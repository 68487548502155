.technologies_wrapper {
  width: 100%;
}

.technolgy_heading {
  text-align: center;
  font-size: 40px;
  color: black;
  padding-top: 50px;
  padding-bottom: 20px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
}