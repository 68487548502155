.contact_card_container {
    display: flex;
    justify-content: space-between;
  }
  
  .contact_main_container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f5f5f5;
    padding-top: 60px;
  }
  
  .contact-section {
    padding-bottom: 30px;
    width: 100%;
  }
  
  .contact-title  {
    color: #0f4c9e;
    font-size: 19px;
  }
  

  .contact-heading h2 {
    font-size: 30px;
    font-weight: 600;
  }
  
  .contact-container-2 {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  
  .contact-container-2 form {
    width: 100%;
  }
  
  .heading-setion .heading-2 {
    font-size: 28px;
    font-weight: 800;
    margin-left: 80px;
    margin-top: 60px;
  }
  
  .paragraph p {
    width: 64%;
    padding-top: 14px;
    line-height: 2.7rem;
    font-size: 19px;
    color: #7e7e7e;
    margin-left: 80px;
    margin-bottom: 30px;
  }
  
  .input-section {
    padding: 0px 40px;
    margin: 0px 40px;
  }
  
  .input-1 input {
    /* height: 40px; */
    padding: 20px;
    width: 100%;
    border: none;
    background-color: rgba(221, 220, 220, 0.418);
    font-size: 18px;
  }
  
  .contact_btn button {
  
  
    background-color: rgba(236, 236, 236, 0.39);
    border: 1.5px solid black;
    font-size: 2rem;
    color: black;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 80px !important;
    padding: 16px 24px;
  }
  
  .contact_btn button:hover {
    background-color: black;
    color: white;
    font-weight: bold;
  }
  
  .text-area {
    padding: 20px;
  }
  
  .contact_card_container {
    gap: 20px;
    justify-content: space-between;
  
  }
  .error-message{
    color: red;
    padding-top: 20px;
    font-size: 15px;
    padding-left: 5px;
  }
  
  @media (max-width:1333px) {
    .contact_card_container {
      justify-content: center;
    }
  }
  
  @media (min-width: 768px) {
    html {
      font-size: 55%;
    }
    .card-1 {
      width: 100%;
    }
  }
  
  .text-area {
    width: 100%;
    margin-top: 30px;
    border: none;
    background-color: rgba(221, 220, 220, 0.418);
    font-size: 2rem;
    padding: 20px;
  }

  .email_input {
    margin-top: 30px;
  }
  
  @media (max-width: 768px) {
  
    .input-section {
      padding: 0px 10px;
    }
  
    .heading-setion .heading-2 {
      margin-left: 5px;
      margin-right: 0px;
    }
  
    .paragraph p {
      margin-left: 5px;
      width: 95%;
      line-height: 30px;
    }
  
    .contact-section {
      padding-top: 30px;
  
      max-width: 450px;
  
    }
    .contact-title  {
      color: #0f4c9e;
      font-size: 14px;
    }
  
    .contact-heading {
      width: 100%;
    }
  
    .contact-heading h2 {
      padding-left: 0;
      width: 100%;
  
    }
  
    .input-section {
      margin: 0px;
    }
  
    .text-area {
  
      padding: 12px;
    }
    .input-1 input {
      /* height: 40px; */
      padding: 12px;
      width: 100%;
      border: none;
      background-color: rgba(221, 220, 220, 0.418);
      font-size: 18px;
    }
  
    .contact_btn button {
      margin: 10px 10px !important;
    }
  }
  
  .contact_card {
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
 
  @media  (min-width:768px) {
    .contact_card_main_container {
      /* padding: 0px 46px; */
    }
  
    .contact_card {
  
      display: flex;
      justify-content: space-between;
      gap: 5px;
      /* width: 100%; */
      z-index: 5;
  
    }
  }
  
  @media (max-width:768px) {
    .contact_card_main_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 33px;
    }
  
    .contact_card {
      flex-direction: column;
  
      z-index: 2;
  
    }
  }
  
  @media (max-width:200px) {
    .contact-title h3 {
      font-size: 2rem;
    }
  
    .services_card_main_container {
      padding: 0px 15px;
    }
  
    .contact-heading h2 {
      margin-top: 20px;
      font-size: 1.5rem;
    }
  
  
  }