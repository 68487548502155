.card_img{
    width: 100px;
    height: 100px;
}
.card-heading h3 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}
.crad-para  {
    font-size: 20px;
    color: #6c6c79;
    margin-bottom: 5px;
    line-height: 1.7;
    cursor: pointer;
    text-decoration: none;
}
.crad-para2  {
    font-size: 20px;
    color: #6c6c79;
    line-height: 1.7;
}
.info{
    font-size: 20px;
}
.card_arrow {
    margin-top: 10px;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    position: relative;

}

.card_arrow {
    margin-top: 10px;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    position: relative;

}

.card_arrow .icon {
    transition: all 0.2s;
}

.card_arrow:hover .icon {
    color: #0f4c9e;
}

.card_arrow .iconLink {
    position: absolute;
    top: 0;
    left: -110px;
    transition: all 0.2s;
    opacity: 0;
    display: flex;
    align-items: center;
    height: 80%;
}

.card_arrow:hover .iconLink {
    right: 0;
    opacity: 1;
}
.iconLink .link {
    font-size: 18px;
    font-weight: 400;
    transform: translateX(-10%);
    transition: all 0.2s;
    text-decoration: none;
}

.card_arrow:hover .iconLink .link {
    transform: translateX(150px);
    color: #0f4c9e;
}

@media (max-width: 786px) {
    .crad-para  {
        font-size: 20px;
        color: #6c6c79;
        margin-bottom: 5px;
        line-height: 1.7;
        cursor: pointer;
        text-decoration: none;
    }
    .info{
        font-size: 20px;
    }
    .crad-para2  {
        font-size: 20px;
        color: #6c6c79;
        line-height: 1.7;
    }
}
@media (max-width: 450px) {
    .crad-para  {
        font-size: 16px;
        color: #6c6c79;
        margin-bottom: 5px;
        line-height: 1.7;
        cursor: pointer;
        text-decoration: none;
    }
    .info{
        font-size: 16px;
    }
    .crad-para2  {
        font-size: 16px;
        color: #6c6c79;
        line-height: 1.7;
    }
}
