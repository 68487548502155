.footer_main_container {
  background-color: hsl(203, 100%, 9%);
  /* background-color: lightblue; */
  color: white;

}
.link_heading {
  font-size: 25px;
  font-weight: 600;
}
.link_text {
  display: flex;
  flex-direction: column;
}
.footer_link {
  font-size: 18px;
  text-decoration: none;
  padding-left: 1px;
  color: white;

}
.address {
  font-size: 18px;
  text-align: start;
}
.contact_us{
  display: flex;
  justify-content: end;
}
.contact {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
}
.copyright_container{
  display: flex;
 justify-content: start;
}
.copyright_text {
  font-size: 18px;
}
.social_icon_container {
  gap: 25px;
  font-size: 18px;
  display: flex;
}
.footer_main_container{
  padding-left: 160px;
  padding-right: 160px;
}
.rubic{
  display: flex;
  justify-content: end;
}
.socil_container{
  display: flex;
  justify-content: end;
}
@media (max-width: 768px) {
  .footer_main_container{
    padding-left: 0px;
    padding-right: 0px;
  }
  .logo-img{
    display: flex;
    justify-content: center;
  }
  .rubic{
    display: none;
  }
  .address {
    display: flex;
    justify-content: center;
    text-align: center;

  }
  .contact_us{
    display: flex;
    justify-content: center;
  }
  .contact_phone {
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .copyright_container{
    display: flex;
   justify-content: center;
  }
  .socil_container{
    display: flex;
    justify-content: center;
  }
}